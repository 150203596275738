import { useDispatch, useSelector } from 'react-redux';
import fm from 'format-message';
import useAccountQuery from 'queries/useAccountQuery';
import useTeamStoreUrlQuery from 'queries/useTeamStoreUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { signOut } from '@activebrands/core-web/state/auth/actions';
import { matchesMedia } from '@activebrands/core-web/utils/responsive';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import stringReplace from '@grebban/utils/string/replaceSubStrings';
import OverlayToggler from 'components/Header/OverlayToggler';
import StateButton from 'components/buttons/StateButton';
import ThemeButton from 'components/buttons/ThemeButton';
import LogOutIcon from 'components/icons/LogOutIcon';
import Paragraph from 'components/text/Paragraph';

const ENABLE_TEAMSTORE = process.env.REACT_APP_ENABLE_TEAMSTORE;

const Wrapper = styled('div', {
    bottom: 'var(--height-header)',
    zIndex: 'calc(var(--zindex-header) - 3)',
    position: 'fixed',
    width: '100%',
    backgroundColor: 'var(--color-bg-navigation-menu)',

    [media.min['mobile.lg']]: {
        top: '4px',
        borderRight: '1px solid var(--color-border-account)',
        gridColumn: '1 / span 2',
        position: 'sticky',
        height: `calc(100dvh - var(--height-header))`,
        backgroundColor: 'transparent',
    },

    [media.min['desktop.sm']]: {
        top: 'var(--height-header)',
        bottom: 0,
        minWidth: '220px',
        zIndex: 0,
    },
});

const Icon = styled('img', {
    marginRight: '8px',
    height: '16px',
    width: '16px',
});

const MyAccountNavigation = () => {
    const [css] = useStyletron();
    const { internalMenu, welcomeLabel, externalMenu } = useAccountQuery();
    const { user } = useSelector(state => state.auth);
    const { teamStoreCategoryPage: teamStoreCategoryPagePath } = useTeamStoreUrlQuery();

    const dispatch = useDispatch();

    const firstName = { '{firstName}': user.firstname || user.email };
    const replacedName = stringReplace(welcomeLabel, firstName);

    const isDesktop = matchesMedia(media.min['desktop.sm']);

    const activeClass = css({
        width: '100%',
        backgroundColor: ['var(--color-bg-active-menu)', null, 'var(--color-bg-navigation-active)'],
        color: ['var(--color-bg-highlight)', null, 'var(--color-contrast-dark)'],
    });

    const hasValidatedTeamAccount = user.teams?.length > 0;

    return (
        <Wrapper>
            <div
                className={css({
                    marginBottom: '12px',
                    padding: '0 12px',
                    display: ['none', null, 'block'],
                    marginTop: '20px',
                })}
            >
                <Paragraph $style={{ marginBottom: '20px', fontSize: '12px' }} as="span" fontKeys="Primary/12_100_-1">
                    {replacedName}
                </Paragraph>
            </div>
            <ScrollableBox
                as="nav"
                className={css({
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: isDesktop ? 0 : '12px 16px',
                    height: 'calc(100% - var(--height-header))',
                    flexDirection: ['row', null, 'column'],
                    width: '100%',
                })}
                scrolldirection="horizontal"
            >
                <ul
                    className={css({
                        display: 'flex',
                        flexDirection: ['row', null, 'column'],
                        gap: ['8px', null, '0'],
                    })}
                >
                    <li
                        className={css({
                            display: ['flex', null, 'none'],
                            height: '40px',
                            backgroundColor: 'var(--color-bg-menu-button)',
                            border: '1px solid var(--color-border-navigation-menu)',
                            padding: '12px',
                            alignItems: 'center',
                        })}
                    >
                        <OverlayToggler overlayId="account-navigation">
                            <Paragraph $style={{ whiteSpace: 'noWrap' }} as="p" fontKeys="Primary/16_100_-05">
                                {fm('Menu')}
                            </Paragraph>
                        </OverlayToggler>
                    </li>
                    {internalMenu?.map((internalMenuItem, index) => (
                        <li
                            className={css({
                                width: '100%',
                                height: ['40px', null, '42px'],
                            })}
                            key={index}
                        >
                            <Link
                                activeClassName={activeClass}
                                className={css({
                                    display: 'inline-flex',
                                    textAlign: 'center',
                                    transitionDuration: 'var(--duration-fast)',
                                    transitionProperty: 'all',
                                    transitionTimingFunction: 'var(--ease)',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    padding: '12px',
                                    border: isDesktop ? 'none' : '1px solid var(--color-border-account-navigation)',
                                    whiteSpace: 'noWrap',
                                    ':hover': {
                                        backgroundColor: 'var(--color-bg-navigation-active)',
                                    },
                                    ...responsiveTextStyles(['Primary/16_100_-05', null, 'Primary/18_100_-05']),
                                })}
                                rel="nofollow"
                                to={internalMenuItem.link.url}
                                onClick={overlay.close('account-navigation')}
                            >
                                {internalMenuItem.label}
                            </Link>
                        </li>
                    ))}
                </ul>
                <ul
                    className={css({
                        display: ['none', null, 'flex'],
                        flexDirection: ['row', null, 'column'],
                        gap: ['8px', null, '16px'],
                    })}
                >
                    {externalMenu &&
                        externalMenu.map((externalMenuItem, index) => {
                            const icon = externalMenuItem.icon[0]?.icon[0]?.filename;

                            return (
                                <li
                                    className={css({
                                        height: '36px',
                                        width: '100%',
                                    })}
                                    key={index}
                                >
                                    <ThemeButton
                                        as={Link}
                                        activeClassName={activeClass}
                                        $style={{
                                            height: ['40px', null, '36px'],
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            padding: '0px 12px',
                                        }}
                                        fontKeys="Primary/14_100_-3"
                                        theme="link"
                                        rel="nofollow"
                                        to={externalMenuItem.link.url}
                                        onClick={overlay.close('account-navigation')}
                                    >
                                        {icon && <Icon src={icon} />}
                                        {externalMenuItem.label}
                                    </ThemeButton>
                                </li>
                            );
                        })}

                    <li
                        className={css({
                            width: '100%',
                            borderTop: '1px solid var(--color-border-account)',
                        })}
                    >
                        {ENABLE_TEAMSTORE === 'true' && (
                            <div style={{ margin: '28px 12px' }}>
                                {hasValidatedTeamAccount ? (
                                    <StateButton
                                        iconSize="16px"
                                        fontKeys="Misc/16_100_-2"
                                        $style={{
                                            height: '40px',
                                        }}
                                        as={Link}
                                        theme="inverted"
                                        to={teamStoreCategoryPagePath}
                                    >
                                        {fm('Go to Team Store')}
                                    </StateButton>
                                ) : (
                                    <ThemeButton
                                        $style={{
                                            width: '100%',
                                        }}
                                        size="md"
                                        theme={{ type: 'solid' }}
                                        onClick={() => {
                                            overlay.open('validate-team-account');
                                        }}
                                    >
                                        {fm('Validate Team Account')}
                                    </ThemeButton>
                                )}
                            </div>
                        )}
                    </li>
                    <li
                        className={css({
                            width: '100%',
                            borderTop: '1px solid var(--color-border-account)',
                        })}
                    >
                        <ThemeButton
                            as={Link}
                            activeClassName={activeClass}
                            $style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                cursor: 'pointer',
                                padding: '18px 12px',
                            }}
                            rel="nofollow"
                            to="/"
                            onClick={() => dispatch(signOut())}
                        >
                            <LogOutIcon $style={{ marginRight: '8px' }} />
                            <Paragraph as="p" fontKeys="Primary/14_100_-3">
                                {fm('Log out')}
                            </Paragraph>
                        </ThemeButton>
                    </li>
                </ul>
            </ScrollableBox>
        </Wrapper>
    );
};

export default MyAccountNavigation;
